export const generateURL = (obj) => {
    // Initialize an array to hold key-value pairs
    const keyValuePairs = [];

    // Loop through the object's properties
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            // Encode the key and value and add them to the array
            const encodedKey = encodeURIComponent(key);
            const encodedValue = encodeURIComponent(obj[key]);
            keyValuePairs.push(`${encodedKey}=${encodedValue}`);
        }
    }

    // Join the key-value pairs with "&" and add them to the base URL
    const queryString = keyValuePairs.join('&');
    const url = `?${queryString}`;

    return url;
}