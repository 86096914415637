import React from 'react'
import { Navigate } from 'react-router-dom'
import Cookies from "universal-cookie";
import { TOKEN_KEY } from '../config';
import { useCookies } from 'react-cookie';
const cookies = new Cookies();


function ProtectedRoutes({ component: Component, ...restOfProps }) {
    const [cookies, setCookie] = useCookies([TOKEN_KEY]);
    let token = cookies[TOKEN_KEY];
    // let role = sessionStorage.getItem('roles');
    const { children } = restOfProps
    if (!token) {
        return <Navigate to="/login" replace />;
    } else {
        return children
    }
}

export default ProtectedRoutes
