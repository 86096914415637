import toast from "react-hot-toast"

export const errorHandle = (err) => {
    if(!err.response || !err){
        toast.error('Network error')
        return 
    }else{
        let message = err.response.data.message
        let status = err.response.status
        if(status >= 400 || status <= 499){
            toast.error(message)
        }else{
            toast.error('Internal Server error')
        }
    }
}