import Layout from '../../components/layout'
import { useQuery } from "@tanstack/react-query";
import Spinner from '../../components/spinner';
import Errors from '../errors';
import { getCustomerList } from '../../apis/customers';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { DATA_VECTOR, ENCRYPT_AND_DECRYPT_KEY } from '../../config';


var CryptoJS = require("crypto-js");
function Customers() {
    const initialState = {
        limit: 10,
        pageNo: 0,
        search: '',
        platformType:'WEB'
    }

    function decrypt(data) {
        var DataEncrypt = data;
        var DataKey = CryptoJS.enc.Utf8.parse(ENCRYPT_AND_DECRYPT_KEY);
        var DataVector = CryptoJS.enc.Utf8.parse(DATA_VECTOR);
        var decrypted = CryptoJS.AES.decrypt(DataEncrypt, DataKey, { iv: DataVector });
        var decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
        setData(JSON.parse(decrypted))
    }

    const [customerState, setCustomerState] = useState(initialState)
    const [dataCount, setDataCount] = useState(0)
    const [data, setData] = useState([])
    const handleGetCustomerList = useQuery(['customerList', customerState], () => getCustomerList(customerState), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onSuccess: (response) => {
            const { pagination } = response.data;
            // console.log({pagination});
            setDataCount(Math.ceil(pagination.totalCount / customerState.limit))
            // decrypt(data)
        }
    })
    const handleSearchVal = (e) => {
        let value = e.target.value
        let data = {
            ...customerState,
            pageNo: 0,
            [e.target.name]: value
        }
        setCustomerState(data)
    }

    const handlePageClick = (val) => {
        let data = {
            ...customerState,
            pageNo: val.selected
        }
        setCustomerState(data)
    }
    console.log(handleGetCustomerList.data);
    return (
        <>
            <Layout>
                <div className='category'>
                    <div className='category__head headingBorder__b'>
                        <h2 className='titleHeading'>Customers</h2>
                    </div>
                    <div className='category_table py-5 space-y-5'>
                        <div className='flex items-center space-x-4'>
                            <div className='flex-1'>
                                <input name='search' value={customerState.search} onChange={handleSearchVal} type="text" placeholder='Search Customer....' className='input' />
                            </div>
                        </div>
                        {handleGetCustomerList.isLoading ? <Spinner />
                            : handleGetCustomerList.status === "error" ? <Errors errorObject={handleGetCustomerList.error} inline /> :
                                <>
                                    <div className="overflow-x-auto bg-white rounded-lg shadow relative">
                                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                            <thead>
                                                <tr className="text-left">
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                        Sr.no
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                        Phone Number
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Customer Name
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Email
                                                    </th>
                                                    {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Address
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        City
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Pincode
                                                    </th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {handleGetCustomerList.data?.data?.data?.length > 0 ? (
                                                    handleGetCustomerList.data?.data?.data?.map((customerData, idx) => {
                                                        const { id, mobile, name, email, address, city, lastName,firstName
                                                        } = customerData;
                                                        return (
                                                            <tr key={id}>
                                                                <td className="border-dashed border-t border-gray-200 userId">
                                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                                        {(customerState.limit * (customerState.pageNo + 1)) - (customerState.limit - (idx + 1))}
                                                                    </span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 firstName">
                                                                    <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                                                                        {mobile|| '---'}
                                                                    </span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                                        {firstName} {lastName}
                                                                    </span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                                    <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                                                                        {email|| '---'}
                                                                    </span>
                                                                </td>
                                                                {/* <td className="border-dashed border-t border-gray-200 lastName">
                                                                    <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                                                                        {address || '---'}
                                                                    </span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 emailAddress">
                                                                    <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                                                                        {city || '---'}
                                                                    </span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                                    <span className="text-gray-700 px-6 py-3 text-center block text-sm">
                                                                    </span>
                                                                </td> */}
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="7" className="text-center py-4">No record found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className=" my-7">
                                        {handleGetCustomerList.data?.data?.data?.length > 0 ? (
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=">"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={dataCount}
                                                previousLabel="<"
                                                renderOnZeroPageCount={null}
                                                containerClassName='flex items-center justify-center space-x-4'
                                                pageLinkClassName='pageNumber'
                                                previousLinkClassName='pageNumber'
                                                nextLinkClassName='pageNumber'
                                                activeLinkClassName='selectedPageNumber'
                                                disabledClassName='lastPage'
                                                disabledLinkClassName='lastPage'
                                            />
                                        ) : (
                                            <div className="text-center"></div>
                                        )}
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Customers