import { useEffect } from "react";
import { NavLink } from "react-router-dom"
import { handleLogoutFromServer } from "../../apis/logout";
import { useNavigate } from 'react-router-dom'
import './header.css'
import IdleTimer from "../../helper/idleTimer";
import { IDLE_TIME_IN_SECONDS } from "../../config";
import Cookies from "universal-cookie";
let role = sessionStorage.getItem('roles');
const cookies = new Cookies();

function Header() {
    const navigate = useNavigate()
    const from = "/login";
    const handleLogout = () => {
        // handleLogoutFromServer()
        //     .then((res) => {
        //         if (res.status === 200) {
        //             sessionStorage.clear();
        //             // window.location.replace("/login")
        //             navigate(from, { replace: true });
        //         }
        //     })
        //     .catch((err) => {
        //         console.error(err);
        //     })
      

      
            cookies.remove('_ecommerce_token_dev');
        window.location.replace("/login")
        // navigate(from, { replace: true });
    }

    useEffect(() => {
        const timer = new IdleTimer({
            timeout: IDLE_TIME_IN_SECONDS, //expire after 10 seconds
            onTimeout: () => {
                handleLogout()
            },
            onExpired: () => {
                //do something if expired on load
                handleLogout()
            }
        });

        return () => {
            timer.cleanUp();
        };
    }, []);

    return (
        <header className=" bg-white sticky top-0 z-20 border-b ">
            <div className="container_xxl">
                <div className="row flex items-center justify-between pb-2">
                    <div className="mt-4">
                        <NavLink to={`/`} className='text-gray-800 font-semibold text-2xl'>Ottowize E-commerce Admin</NavLink>
                    </div>
                    <div>
                        <div className="flex flex-row justify-between w-full mt-2 space-x-4">
                            <ul className="flex items-center w-full space-x-8 text-gray-400 font-semibold text-base py-2">
                                <li className="linkItem">
                                    <NavLink to={`/`} end className={({ isActive }) => `${isActive ? 'text-primary' : ''}`}>Dashboard</NavLink>
                                </li>
                                {/* <li className="linkItem">
                                    <NavLink to={`/brands`} className={({isActive}) > {`${isActive ?text-primary '':''}`}>Brands</NavLink>
                                </li> */}
                                <li className="linkItem">
                                    <NavLink to={`/products`} className={({ isActive }) => `${isActive ? 'text-primary' : ''}`}>Products</NavLink>
                                </li>
                                <li className="linkItem">
                                    <NavLink to={`/category`} className={({ isActive }) => `${isActive ? 'text-primary' : ''}`}>Categories</NavLink>
                                </li>
                                {/* <li className="linkItem">
                                    <NavLink to={`/frames`} className={({isActive}) > {`${isActive ?text-primary '':''}`}>Frames</NavLink>
                                </li> */}
                                <li className="linkItem">
                                    <NavLink to={`/orders`} className={({ isActive }) => `${isActive ? 'text-primary' : ''}`}>Orders</NavLink>
                                </li>
                                {/* <li className="linkItem">
                                    <NavLink to={`/taxes`} className={({ isActive }) => `${isActive ? 'text-primary' : ''}`}>Taxes</NavLink>
                                </li> */}
                                <li className="linkItem">
                                    <NavLink to={`/customers`} className={({ isActive }) => `${isActive ? 'text-primary' : ''}`}>Customers</NavLink>
                                </li>
                                <li className="linkItem">
                                    <NavLink to={`/reports`} className={({ isActive }) => `${isActive ? 'text-primary' : ''}`}>Reports</NavLink>
                                </li>
                                {/* <li className="linkItem">
                                    <NavLink to={`/coupons`} className={({ isActive }) => `${isActive ? 'text-primary' : ''}`}>Coupons</NavLink>
                                </li> */}
                                {role === 'idigitize' &&
                                    <li className="linkItem">
                                        <NavLink to={`/blogs`} className={({ isActive }) => `${isActive ? 'text-primary' : ''}`}>Blogs</NavLink>
                                    </li>}

                            </ul>
                            <div className="ml-auto">
                                <button onClick={handleLogout} className="px-4 py-2 rounded text-white bg-[color:var(--color3)]">Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header