import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config";
import Cookies from "universal-cookie";
import { generateURL } from "../helper/utiles";

// const cookies = new Cookies();
let token = sessionStorage.getItem(TOKEN_KEY);
const cookies = new Cookies();

export const getProductList = async (data) => {
    // let token = cookies.get(TOKEN_KEY);
    let res = await axios({ method: "GET", url: `${API_ENDPOINT}v1/ec/products${generateURL(data)}` })
    return res
};


export const handleUpdateProductStatus = async (data) => {
    // let token = cookies.get(TOKEN_KEY);
    const { id, home } = data
    let fd = new FormData()
    fd.append('id', id)
    fd.append('home', home)
    let res = await axios({ method: "POST", data: fd, url: `${API_ENDPOINT}productstatus`, headers: { Authorization: token ? `Bearer ${token}` : '', } },)
    return res
};

export const addProduct = async (data) => {
    // let token = cookies.get(TOKEN_KEY);
    // let fd = new FormData()
    // for (var key in data) {
    //     if (key === 'images_of_product') {
    //         for (let i = 0; i < data['images_of_product'].length; i++) {
    //             fd.append('images_of_product[]', data['images_of_product'][i]);
    //         }
    //     }
    //     else if (Array.isArray(data[key])) {
    //         fd.append(key, JSON.stringify(data[key]));
    //     } else {
    //         fd.append(key, data[key]);
    //     }
    // }
    let token = cookies.get(TOKEN_KEY);
    let res = await axios({
        method: "POST",
        data: data,
        url: `${API_ENDPOINT}v1/ec/products`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
    return res
};
export const editProduct = async (data) => {
    let token = cookies.get(TOKEN_KEY);
    // let fd = new FormData()
    // for (var key in data) {
    //     if (key === 'images_of_product') {
    //         for (let i = 0; i < data['images_of_product'].length; i++) {
    //             fd.append('images_of_product[]', data['images_of_product'][i]);
    //         }
    //     }
    //     else if (Array.isArray(data[key])) {
    //         fd.append(key, JSON.stringify(data[key]));
    //     } else {
    //         fd.append(key, data[key]);
    //     }
    // }
    let res = await axios({ method: "POST", data: data, url: `${API_ENDPOINT}v1/ec/products/update`, 
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    }})
    return res
};

export const deleteProduct = async (productId) => {
    let token = cookies.get(TOKEN_KEY);
    let res = await axios({ method: "POST", data: {productId}, url: `${API_ENDPOINT}v1/ec/products/delete`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    } 
    })
    return res
};

export const addImage = async (d) => {
    let token = cookies.get(TOKEN_KEY);
    const { isBanner, altText, image, type } = d
    let data = new FormData();
    data.append('image', image);
    data.append('altText', altText);
    data.append('isBanner', isBanner);

    let res = await axios({
        method: "POST",
        data: data,
        url: `${API_ENDPOINT}v1/ec/images`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }

    })

    return { res, type }
};