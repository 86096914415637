import './App.css';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Login from './pages/auth/Login';
import Dashboard from './pages/dashboard';
import Category from './pages/category';
import Products from './pages/products';
import Services from './pages/services';
import Orders from './pages/orders';
import Customers from './pages/customers';
import Coupons from './pages/coupons';
// import SoldProducts from './pages/reports/SoldProducts';
// import ProductViewed from './pages/reports/ProductViewed';
import ProtectedRoutes from './routes/ProtectedRoutes';
import Reports from './pages/reports';
import { useEffect } from 'react';
import { handleLogoutFromServer } from './apis/logout';
import IdleTimer from "./helper/idleTimer";
import { useNavigate } from 'react-router-dom'
import { IDLE_TIME_IN_SECONDS } from './config';
import Blogs from './pages/blogs';
import AddProduct from './pages/products/AddProduct';
import Taxes from './pages/taxes';
import Brands from './pages/brands';
import Frames from './pages/frames';
import { CookiesProvider } from 'react-cookie';
import { Toaster } from 'react-hot-toast';


const queryClient = new QueryClient()
function App() {
  const handleLogout = () => {
    // handleLogoutFromServer()
    //   .then((res) => {
    //     if (res.status === 200) {
    //       sessionStorage.clear();
    //       window.location.replace("/login")
    //       // navigate(from, { replace: true });
    //       localStorage.removeItem('_expiredTime')
    //     }
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   })
    sessionStorage.clear();
    window.location.replace("/login")
    // navigate(from, { replace: true });
    localStorage.removeItem('_expiredTime')
  }

  useEffect(() => {
    const timer = new IdleTimer({
      timeout: IDLE_TIME_IN_SECONDS, //expire after 10 seconds
      onTimeout: () => {
        handleLogout()
      },
      onExpired: () => {
        handleLogout()
      }
    });

    return () => {
      timer.cleanUp();
    };
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster/>
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
    
        <Router>
          <Routes>
          
            <Route path='/login' exact element={<Login />} />
            <Route path='/' exact element={<ProtectedRoutes><Dashboard /></ProtectedRoutes>} />
            <Route path='/category' exact element={<ProtectedRoutes><Category /></ProtectedRoutes>} />
            <Route path='/products' exact element={<ProtectedRoutes><Products /></ProtectedRoutes>} />
            <Route path='/products/add-product' exact element={<ProtectedRoutes><AddProduct /></ProtectedRoutes>} />
            <Route path='/services' exact element={<ProtectedRoutes><Services /></ProtectedRoutes>} />
            <Route path='/orders' exact element={<ProtectedRoutes><Orders /></ProtectedRoutes>} />
            <Route path='/customers' exact element={<ProtectedRoutes><Customers /></ProtectedRoutes>} />
            {/* <Route path='/coupons' exact element={<ProtectedRoutes><Coupons /></ProtectedRoutes>} /> */}
            <Route path='/category' exact element={<ProtectedRoutes><Category /></ProtectedRoutes>} />
            <Route path='/reports' exact element={<ProtectedRoutes><Reports /></ProtectedRoutes>} />
            <Route path='/blogs' exact element={<ProtectedRoutes><Blogs /></ProtectedRoutes>} />
            {/* <Route path='/taxes' exact element={<ProtectedRoutes><Taxes /></ProtectedRoutes>} /> */}
            {/* <Route path='/filters' exact element={<ProtectedRoutes><Filters /></ProtectedRoutes>} /> */}
            <Route path='/brands' exact element={<ProtectedRoutes><Brands /></ProtectedRoutes>} />
            <Route path='/frames' exact element={<ProtectedRoutes><Frames /></ProtectedRoutes>} />
          </Routes>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
    </CookiesProvider>
      </QueryClientProvider>
  );
}

export default App;
