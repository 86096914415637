import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config";
import Cookies from "universal-cookie";


const cookies = new Cookies();
let token = sessionStorage.getItem(TOKEN_KEY);



export const downloadReports = async (data) => {
    let token = cookies.get(TOKEN_KEY);
 
    let res = await axios(
        {
            method: "POST",
            url: `${API_ENDPOINT}v1/adop/get-report`,
            responseType: 'blob',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }

        })
    return res
};