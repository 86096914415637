import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config"; 
import Cookies from "universal-cookie";
import { generateURL } from "../helper/utiles";

 const cookies = new Cookies();
let token = sessionStorage.getItem(TOKEN_KEY);

export const getCustomerList = async (data) => { 
    let token = cookies.get(TOKEN_KEY);
 
    let res = await axios({ method: "GET", 
    url: `${API_ENDPOINT}v1/users/get-users${generateURL(data)}`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    }})
    return res
};

// let res = await axios({ method: "GET", url: `${API_ENDPOINT}v1/ec/products` })
// return res