import { useMutation } from "@tanstack/react-query";
import { handleLogin } from "../../apis/auth";
import toast from 'react-hot-toast';
import { useCookies } from 'react-cookie';
import { useState } from "react";
import { errorHandle } from "../../helper/errorHandling";
import {useNavigate} from 'react-router-dom'
import { TOKEN_KEY } from "../../config";

const useLogin = () => {
    const navigate = useNavigate()
    const [cookies, setCookie] = useCookies([TOKEN_KEY]);
    const handleLoginUser = useMutation((data) => handleLogin(data), {
        onSuccess: (response) => {
            toast.success('Login Success')
            setCookie(TOKEN_KEY, response.data.data.token)
            navigate('/', { replace: true })
        },
        onError: (err) => {
            // console.log('err', err);
            errorHandle(err)

        }
    })
    return handleLoginUser
}

export default useLogin;
