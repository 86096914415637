import { useState } from 'react'
import spinner from '../../assets/images/common/spinner.gif'
import logo from "../../assets/images/common/ottowise_logo.png"
import { useMutation } from "@tanstack/react-query";
import { handleLogin } from '../../apis/auth';
import Cookies from 'universal-cookie';
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY, TOKEN_KEY } from '../../config';
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { Toaster } from 'react-hot-toast';
import useLogin from '../../hooks/auth/useLogin';
import { useCookies } from 'react-cookie';
const Login = () => {
    const [cookies, setCookie] = useCookies([TOKEN_KEY]);
    const { setAuth } = useAuth();
    const navigate = useNavigate()
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    let token = sessionStorage.getItem(TOKEN_KEY);
    const initialData = {
        email: '',
        password: '',
        reToken: null,
    }
    const [userData, setuserData] = useState(initialData)
    const [error, setError] = useState('')
    const [recaptchaError, setRecaptchaError] = useState('')
    const handleChangeInput = (e) => {
        let value = e.target.value
        let data = {
            ...userData,
            [e.target.name]: value
        }
        setuserData(data)
    }
    const handleAuth = useLogin()
 
    const handleSubmitLoginForm = (e) => {
        e.preventDefault()
        // if (!userData.reToken || userData.reToken === '') return setRecaptchaError('Please Solve The reCaptcha')
            handleAuth.mutate(userData)
    }
    const onChange = (value) => {
        let data = {
            ...userData,
            reToken: value
        }
        setRecaptchaError('')
        setuserData(data)
    }
    if (cookies[TOKEN_KEY]) return <Navigate to="/" replace />
    return <>
     {/* <Toaster/> */}
        <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-gray-100">
            <div className="flex flex-col items-center justify-center w-full flex-1 px-20 ">
                <div className="bg-white rounded-2xl shadow-2xl flex w-2/3 max-w-4xl">
                    <div className="w-3/5 py-20 px-9">
                        <p className=" text-3xl font-semibold mb-4 roboto">Login</p>
                        <form className=" space-y-4" autoComplete='on' onSubmit={handleSubmitLoginForm}>
                            <div>
                                <label htmlFor="email" className=" block text-xl roboto">Email</label>
                                <input type="text" required
                                    className="border-solid border-2 border-gray-400 py-2 px-2 rounded w-full mt-2 placeholder:italic placeholder:text-sm"
                                    name="email" placeholder="Enter your email here" value={userData.email} onChange={handleChangeInput} />
                            </div>
                            <div>
                                <label htmlFor="password" className=" block text-xl roboto">Password</label>
                                <input autoComplete='off' type="password" required
                                    className="border-solid border-2 border-gray-400 py-2 px-2 rounded w-full mt-2 placeholder:italic placeholder:text-sm"
                                    name="password" placeholder="Enter your password here" value={userData.password} onChange={handleChangeInput} />
                            </div>
                            {/*{error && <div className='text-red-700 text-center'><small>{error}</small></div>}
                            <div className='flex items-center justify-center'>
                                <ReCAPTCHA sitekey={SITE_KEY} onChange={onChange} />
                                </div> */}
                            <div className='text-red-700 text-center'><small>{recaptchaError}</small></div>
                            {handleAuth.isLoading ? <div className=' w-8 h-8 mx-auto'>
                                <img src={spinner} alt="spinner" className='w-full h-full' />
                            </div> : <div className="button">
                                <button className=" w-full py-2 bg-[color:var(--color1)] text-white rounded roboto font-semibold text-2xl">Login</button>
                            </div>}
                        </form>
                    </div>
                    <div className="w-2/5 px-12 text-center flex flex-col justify-center items-center bg-white border-l-2">
                        <p className=" roboto font-semibold">Welcome To <span className="font-extrabold">Ottowize Ecommerce</span></p>
                        <div className="mt-8 w-60 h-60 relative left-2">
                            <img src={logo} alt="logo" className='w-full h-full' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>


}

export default Login