import React from 'react'

function Errors({ errorObject, inline }) {
    const { code, message } = errorObject
    return (
        <>
            <div className={`w-full ${inline ? '' : 'h-screen bg-gray-200'}`}>
                <div className='flex items-center justify-center h-full'>
                    <div className=' bg-white p-10 rounded-lg shadow-lg space-y-4 mt-16'>
                        <h2 className=' font-semibold text-gray-700 text-3xl capitalize'>oops! {message}</h2>
                        <div className='text-center'>
                            <button className='w-full bg-gray-200 text-gray-700 rounded py-2 px-4' onClick={() => { window.location.reload() }}>Retry</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Errors