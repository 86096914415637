import './footer.css'

function Footer() {
  return (
    <div className='bg-white text-black text-center py-2 border-t'>
      <small>Ottowize Ecommerce Dashboard</small>
    </div>
  )
}

export default Footer