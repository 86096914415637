/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config";
import Cookies from 'universal-cookie';
import { generateURL } from "../helper/utiles";



const cookies = new Cookies();
let token = sessionStorage.getItem(TOKEN_KEY);

export const getCategoryList = async (key) => {
    // let q = key.queryKey[1];
    // let token = cookies.get(TOKEN_KEY);
    // let res = await q({ method: "GET", url: `${API_ENDPOINT}categorylist`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    let res = await axios({ method: "GET", url: `${API_ENDPOINT}v1/ec/categories${generateURL(key)}` })
    return res
};
export const handleUpdateCategoryStatus = async (data) => {
    console.log(data);
    let token = cookies.get(TOKEN_KEY);

    // let token = cookies.get(TOKEN_KEY);
    const { id, home } = data
    let fd = new FormData()
    fd.append('id', id)
    fd.append('home', home)
    let res = await axios({
        method: "POST",
        data: fd, url: `${API_ENDPOINT}v1/ec/update-category`, headers: { Authorization: token ? `Bearer ${token}` : '', }
    },)
    return res
};
export const addCategory = async (d) => {
    // const [cookies, setCookie] = useCookies([TOKEN_KEY]);
    // if (!cookies || !cookies[TOKEN_KEY]) {
    //     throw new Error("No token found in cookies");
    // }
    let token = cookies.get(TOKEN_KEY);
    const data = JSON.stringify(d)
    let res = await axios({
        method: "POST",
        data: data,
        url: `${API_ENDPOINT}v1/ec/categories`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }

    })
    return res
};

export const editCategory = async (data) => {
    let token = cookies.get(TOKEN_KEY);
    let fd = new FormData()
    for (var key in data) {
        fd.append(key, data[key]);
    }
    let res = await axios({ method: "POST", 
    data: data,
     url: `${API_ENDPOINT}v1/ec/update-category`, 
     headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    }})
    return res
};
export const deleteCategory = async (id) => {
    // let token = cookies.get(TOKEN_KEY);
    let fd = new FormData()
    fd.append('id', id)
    let res = await axios({ method: "POST", data: fd, url: `${API_ENDPOINT}categorydelete`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};

