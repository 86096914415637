import React, { useEffect, useRef, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { FileUploader } from "react-drag-drop-files";
import Button from '@mui/material/Button';
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { addImage, addProduct } from '../../apis/product';
import Spinner from '../../components/spinner';
import MuiAlert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import { GrAdd } from 'react-icons/gr'
import Configurations from '../../components/section/configurations';
import { useNavigate } from 'react-router-dom';
import ProductDetails from '../../components/elements/ProductDetails/ProductDetails';
import useRead from '../../hooks/useRead';
import useReadUpdated from '../../hooks/updated/useRead';
import { getCategoryList } from '../../apis/category';
import toast from 'react-hot-toast';
import { throwError } from '../../utilities/errorHandling';

const fileTypes = ["JPG", "PNG", "JPEG"];
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function AddProduct() {
    const navigate = useNavigate();
    const productImageUpload = useRef(null)
    const [categoryOptions, setCategoryOption] = useState([])
    // const [taxListOptions, setTaxListOptions] = useState([])
    const queryClient = useQueryClient()
    const initialState = {
        name: '',
        price: '',
        slug: '',
        discountedPrice: '',
        taxPercentage: '',
        quantity: '',
        description: '',
        product_details: [],
        product_thumbnail: '',
        product_thumbnail_preview: '',
        images_of_product: [],
        images_of_product_preview: [],
        seo: {
            'url_key': "",
            'meta_title': "",
            'meta_keywords': "",
            'meta_description': "",
        },
        variants: []
    }
    const [productData, setProductData] = useState(initialState)
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const setImageToServer = useMutation((data) => addImage(data), {
        onSuccess: (data) => {
            const { res, type } = data
            if (type === 'banner') {
                let temp = {
                    ...productData,
                    product_thumbnail_preview: res.data.data.path,
                    product_thumbnail: res.data.data.id
                }
                setProductData(temp)
                toast.success('Product Thumbnail Added')
            } else {
                let temp = {
                    ...productData,
                    images_of_product: [...productData.images_of_product, res.data.data.id],
                    images_of_product_preview: [...productData.images_of_product_preview, res.data.data.path]
                }
                setProductData(temp)
                toast.success('Product Images Added')
            }
        },
        onError: (err) => {
            setIsError(true)
        }
    })

    const handleAddFromData = (e, file, isFileObject, name) => {
        let data;
        if (file) {
            if (isFileObject) {
                // call the image upload api here
                setImageToServer.mutate({
                    image: e,
                    isBanner: 1,
                    altText: e.name.split('.')[0],
                    type: 'banner'
                })
                return
            } else {
                let fileObject = e.target.files[0]
                data = {
                    ...productData,
                    [e.target.name]: fileObject
                }
            }
        } else {
            if (e.target.name === 'seo') {
                let value = e.target.value
                let tempName = e.target.attributes['data-name'].value
                data = {
                    ...productData,
                    seo: {
                        ...productData?.seo,
                        [tempName]: value
                    }
                }
            } else if (e.target.name === 'name') {
                // set slug
                let value = e.target.value
                data = {
                    ...productData,
                    name: value,
                    slug: value.replace(/\s+/g, '-').toLowerCase()
                }
            } else {
                let value = e.target.value
                data = {
                    ...productData,
                    [e.target.name]: value
                }
            }
        }
        setProductData(data)
    }

    const postProductDataToServer = useMutation((data) => addProduct(data), {
        onSuccess: (data) => {
            queryClient.invalidateQueries('products')
            setIsSuccess(true)
            // navigate(-1)
            // setTimeout(() => {
            //     handleCloseAndReset()
            // }, 2000) 
            toast.success('Product Created')
            navigate('/products')

        },
        onError: (err) => {
            throwError(err)
            setIsError(true)
        }
    })
    const closeErrorMessage = () => {
        setIsError(false)
    }
    const handleProductSubmit = (e) => {
        e.preventDefault()
        let images = [ productData.product_thumbnail ]
        for (let i = 0; i < productData.images_of_product.length; i++) {
            images.push(  productData.images_of_product[i] )
        }
        const finalData = {
            images: images,
            productDetails: productData.product_details,
            thumbId: productData.product_thumbnail,
            active: 1,
            categoryId: productData.category,
            quantity: +productData.quantity,
            price: +productData.price,
            discountedPrice: +productData.discountedPrice,
            name: productData.name,
            seo: JSON.stringify(productData?.seo),
            slug: productData.slug
        }
        postProductDataToServer.mutate(finalData)
    }
    const handleCloseAndReset = () => {
        setProductData(initialState)
        closeErrorMessage()
    }
    const handleuploadAgain = () => {
        let data = {
            ...productData,
            product_thumbnail_preview: '',
            product_thumbnail: ''
        }
        setProductData(data)
    }
    useEffect(() => {
        isError && setTimeout(() => setIsError(false), [4000])
    }, [isError])

    const handleGetCategoryList = useQuery(['categories'], getCategoryList, {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            let temp = []
            temp = data.data.data
            setCategoryOption(temp)
        }
    })


    // const { list } = useReadUpdated({
    //     url: 'gettaxList',
    //     initialData: initialState,
    //     onSuccess: (data) => {
    //         setTaxListOptions(data.data)
    //     }
    // })


    const [openUploadImagesModal, setOpenUploadImagesModal] = useState(false)
    const handleOpenUploadModal = () => {
        setOpenUploadImagesModal(true)
    }
    const handleCloseUploadImageModal = () => {
        setOpenUploadImagesModal(false)
    }
    const handleClickOpenFileDialog = () => {
        productImageUpload.current.click()
    }
    const handleUploadImages = (e) => {
        // call the image upload api here
        setImageToServer.mutate({
            image: e.target.files[0],
            isBanner: 0,
            altText: e.target.files[0].name.split('.')[0],
            type: 'normal_image'
        })
        return
    }

    const handleRemoveSelectedProductImage = (e, id) => {
        e.stopPropagation();
        let predData = productData
        predData.images_of_product_preview.splice(id, 1)
        predData.images_of_product.splice(id, 1)
        setProductData({ ...productData })
    }

    const [openConfigModal, setOpenConfigModal] = useState(false)
    const handleOpenConfigurationModal = () => {
        setOpenConfigModal(true)
    }

    return (
        <>
            <div className='container_xxl bg-white'>
                <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                    <div>
                        <IconButton onClick={() => navigate(-1)}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h1 className='heading'>Add Product</h1>
                    </div>
                </div>
                <div className='form-body py-8'>
                    <form className='' autoComplete='off' onSubmit={handleProductSubmit}>
                        <div className='grid grid-cols-1 gap-4'>
                            <div>
                                <label htmlFor="Product Name" className='label'>Product Name <small className="text-red-700">*</small></label>
                                <input required={true} type="text" placeholder='Product Name' name='name' className='input' value={productData.name} onChange={handleAddFromData} />
                            </div>
                            <div>
                                <label className='label'>Slug</label>
                                <input required={true} type="text" placeholder='Slug' name='slug' className='input' value={productData.slug} onChange={handleAddFromData} />
                            </div>
                            {/* <div>
                                <label htmlFor="Product Name" className='label'>Product Description</label>
                                <textarea required={true} className='input min-h-[10rem]' placeholder='Description' name='description' value={productData.description} onChange={handleAddFromData}></textarea>
                            </div> */}
                        </div>
                        <div>
                            <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Product Details</h1>
                            </div>
                            <div className=' space-y-7'>
                                <div className={`grid grid-cols-5 gap-4`}>

                                    <div>
                                        <label className='label'>Price</label>
                                        <input required={true} type="number" placeholder='MRP Price' name='price' className='input' value={productData.price} onChange={handleAddFromData} />
                                    </div>
                                    <div>
                                        <label className='label'>Discounted Price</label>
                                        <input required={true} type="number" placeholder='Selling Price' name='discountedPrice' className='input' value={productData.discountedPrice} onChange={handleAddFromData} />
                                    </div>
                                   <div>
                                         <label className='label'>Quantity</label>
                                        <input required={true} type="number" placeholder='quantity' name='quantity' className='input' value={productData.quantity} onChange={handleAddFromData} />
                                    </div>
                                   <div>
                                         <label className='label'>Tax</label>
                                        <input required={true} type="number" placeholder='Tax' name='taxPercentage' className='input' value={productData.taxPercentage} onChange={handleAddFromData} />
                                    </div>
                                    <div>
                                        <label className='label'>Category</label>
                                        <select required={true} name="category" className='input' value={productData.category} onChange={handleAddFromData}>
                                            <option value="">--Select--</option>
                                            {
                                                categoryOptions?.map((it) => {
                                                    const { id, name } = it
                                                    return (
                                                        <option key={id} value={id}>{name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Search Engine Optimization</h1>
                            </div>
                            <div>
                                <div className='mb-2'>
                                    <label className='label'>URL KEY</label>
                                    <input type="text" placeholder='URL KEY' name="seo" data-name='url_key' className='input' value={productData.seo['url_key']} onChange={handleAddFromData} />
                                </div>
                                <div className='mb-2'>
                                    <label className='label'>Meta Title</label>
                                    <input type="text" placeholder='Meta Title' name="seo" data-name='meta_title' className='input' value={productData.seo['meta_title']} onChange={handleAddFromData} />
                                </div>
                                <div className='mb-2'>
                                    <label className='label'>Meta Keywords</label>
                                    <input type="text" placeholder='Meta Keywords' name="seo" data-name='meta_keywords' className='input' value={productData.seo['meta_keywords']} onChange={handleAddFromData} />
                                </div>
                                <div className='mb-2'>
                                    <label className='label'>Meta Description</label>
                                    <input type="text" placeholder='Meta Description' name="seo" data-name='meta_description' className='input' value={productData.seo['meta_description']} onChange={handleAddFromData} />
                                </div>
                            </div>
                        </div>

                        <ProductDetails
                            productData={productData}
                            setProductData={setProductData}
                        />

                        <div className='grid grid-cols-2 gap-4'>
                            <div>
                                <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                    <div className='pb-3'>
                                        <h1 className='font-semibold text-2xl'>Thumbnail of product</h1>
                                        <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                    </div>
                                </div>
                                <div className=' space-y-4'>
                                    {!productData.product_thumbnail_preview ?
                                        <FileUploader name="product_thumbnail" types={fileTypes} handleChange={(e) => handleAddFromData(e, true, true, 'product_thumbnail')} classes="file-uploader" hoverTitle='Drop here' />
                                        : <div className=' border border-dashed border-blue-700 rounded'>
                                            <div className=' w-40 mx-auto py-4 text-center space-y-3'>
                                                <img src={productData.product_thumbnail_preview} alt="preview" />
                                                <Button variant="outlined" onClick={handleuploadAgain}>Re-Upload</Button>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                            <div>
                                <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                    <div className='pb-3'>
                                        <h1 className='font-semibold text-2xl'>Images of product</h1>
                                        <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                    </div>
                                </div>
                                <div className='flex items-center justify-center mt-12'>
                                    <button onClick={(e) => handleOpenUploadModal(e)} type='button' className='border-gray-800 bg-gray-800 text-white px-6 py-2 rounded mb-2'>
                                        <span>Add Images</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='text-center my-6'>
                            {postProductDataToServer.isLoading ?
                                <Spinner /> :
                                <Button type='submit' variant="contained" className='primaryBtn-contained'>
                                    Save Product
                                </Button>}
                        </div>
                    </form>
                </div>
                {isError && <Alert onClose={closeErrorMessage} className='mb-4' severity="error" sx={{ width: '100%' }}>{postProductDataToServer?.error?.message}, Please try again later</Alert>}
                {isSuccess && <Alert onClose={closeErrorMessage} className='mb-4' severity="success" sx={{ width: '100%' }}>Product Added Succesfully</Alert>}
            </div>
            <Modal open={openUploadImagesModal} onClose={handleCloseUploadImageModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className=' w-full absolute top-4 left-1/2 -translate-x-1/2'>
                    <div className=' max-w-5xl mx-auto bg-white'>
                        <div className=' divide-y'>
                            <div className="head-modal">
                                <div className='flex items-center space-x-4 px-6'>
                                    <div className="w-7 h-7 cursor-pointer" onClick={handleCloseUploadImageModal}>
                                        <svg className='w-full h-full' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                        </svg>
                                    </div>
                                    <h2 className='heading text-3xl'>Upload Product Images</h2>
                                </div>
                            </div>
                            <div className="body-modal py-4 px-6">
                                <div className='max-h-[20rem] min-h-[20rem] overflow-auto  relative'>
                                    <div className='imagePreview__container relative z-10'>
                                        <div className="grid grid-cols-4 gap-4 ">
                                            {productData.images_of_product_preview.map((img, idx) => {
                                                return <div className=' w-full  h-48 overflow-hidden border p-2 relative cursor-default' key={idx}>
                                                    <div className='w-6 h-6 cursor-pointer float-right' onClick={(e) => handleRemoveSelectedProductImage(e, idx)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-full h-full">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </div>
                                                    <img src={img} alt="img" className='w-full h-full object-contain' />
                                                </div>
                                            })}
                                            <div onClick={handleClickOpenFileDialog} className='w-full h-48 cursor-pointer select-none border-2 rounded-md border-dashed border-gray-400 flex flex-col items-center justify-center hover:shadow-lg'>
                                                <GrAdd className=' w-16 h-16 text-gray-400' />
                                                <small>Add Product Image</small>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {productData.images_of_product_preview.length <= 0 && <span className='text-gray-400 text-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>Click here to Upload</span>} */}
                                </div>
                                <input type="file" className='hidden' ref={productImageUpload} onChange={handleUploadImages} multiple accept="image/jpeg, image/png, image/jpg" />
                            </div>
                            <div className="foot-modal py-4 text-center">
                                <Button variant="contained" className='primaryBtn-contained px-10' onClick={handleCloseUploadImageModal}>Save Images</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AddProduct