import { Button, Dialog, IconButton, Modal } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FileUploader } from "react-drag-drop-files";
import React, { useEffect, useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { editCategory } from "../../apis/category";
import Spinner from '../../components/spinner';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function EditCategory({ open, onClose, editData }) {
    const queryClient = useQueryClient()
    const initialState = {
        id: '',
        name: '',
        color: ''
    }
    const [categoryData, setCategoryData] = useState(editData)
    useEffect(() => {
        setCategoryData(editData)
    },[editData])
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const handleInputData = (e) => {
        let value = e.target.value
        let data = {
            ...categoryData,
            [e.target.name]: value,
        }
        
        setCategoryData(data)
    }

    const handleCloseAndReset = () => {
        onClose()
        setIsSuccess(false)
        setIsError(false)
        setCategoryData(initialState)
    }
    const setCategoryDataToServer = useMutation((data) => editCategory(data), {
        onSuccess: (data) => {
            // if (data.status === 200) {
                queryClient.invalidateQueries('categories')
                setIsSuccess(true)
                setTimeout(() => {
                    handleCloseAndReset()
                }, 2000)
            // }
        },
        onError: () => {
            setIsError(true)
        }
    })
    const handleAddCategory = (e) => {
        e.preventDefault()
        let fd = {
            ...categoryData,
            categoryId: categoryData.id
        }
        delete fd.id;
        setCategoryDataToServer.mutate(fd)
    }

    const closeErrorMessage = () => {
        setIsError(false)
    }

    return (
        <>
            <Modal fullScreen maxWidth={'md'} open={open} onClose={handleCloseAndReset} TransitionComponent={Transition}>
                <div className='bg-white pt-0 p-6 max-w-52 max-w-2xl m-auto mt-24'>
                    <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                        <div>
                            <IconButton onClick={handleCloseAndReset}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div>
                            <h1 className='heading'>Edit Category</h1>
                        </div>
                    </div>
                    <div className='form-body pt-8'>
                        <form className=' space-y-4' onSubmit={handleAddCategory}>
                            <div className=' space-y-3'>
                                <div>
                                    <label htmlFor="Product Name" className='label'>Title for Category <small className="text-red-700">*</small></label>
                                    <input required type="text" placeholder='Enter Title' name='name' className='input' value={categoryData.name} onChange={handleInputData} />
                                </div>
                                <div>
                                    <label htmlFor="Product Name" className='label'>Category Color<small className="text-red-700">*</small></label>
                                    <input required type="color" placeholder='Enter Sub-Title' name='color' className='h-[2.5rem] input' value={categoryData.color} onChange={handleInputData} />
                                </div>
                            </div>
                            {isError && <Alert onClose={closeErrorMessage} className='mb-4' severity="error" sx={{ width: '100%' }}>{setCategoryDataToServer?.error?.message}, Please try again later</Alert>}
                            {isSuccess && <Alert onClose={closeErrorMessage} className='mb-4' severity="success" sx={{ width: '100%' }}>Category Added Succesfully</Alert>}
                            <div className=' space-x-4 text-center pb-5'>
                                {setCategoryDataToServer.isLoading ?
                                    <Spinner /> :
                                    <>
                                        <Button type='submit' variant="contained">Save</Button>
                                        <Button variant="outlined" onClick={handleCloseAndReset}>Close</Button>
                                    </>}
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default EditCategory