import React, { useState } from 'react'
import Layout from '../../components/layout';
import AddBrand from './AddBrand';
import EditBrand from './EditBrand';
import { useQuery } from '@tanstack/react-query';
import useRead from '../../hooks/useRead';
import Spinner from '../../components/spinner';
import Errors from '../errors';
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import useCreate from '../../hooks/useCreate';

const Brands = () => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [editData, setEditData] = useState(null)
  const [brands, setBrand] = useState([])

  const handleGetProductList = useRead({
    url: 'getBrandList',
    initialData: {}
  })


  const handleAddBrand = () => {
    setIsOpenAddModal(true)
  }

  const handleEditBrand = (data) => {
    setIsOpenEditModal(true)
    setEditData({ ...data, logo_preview: data?.logo })
  }

  const handleCloseAddModal = () => {
    setIsOpenAddModal(false)
  }

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false)
    setEditData(null)
  }

  const { setDataToServer: setDeleteBrand } = useCreate({
    refreshUrl: 'getBrandList',
    url: 'deleteBrand',
    onSuccess: () => { },
    onError: () => { }
  })

  const handleDeleteBrand = (id) => {
    console.log(id)
    setDeleteBrand.mutate({
      brand_id: id
    })
  }

  return (
    <>
      <Layout>
        <div className='category'>
          <div className='category__head headingBorder__b'>
            <h2 className='titleHeading'>Brands</h2>
          </div>
          <div className='my-4 flex justify-end'>
            <Button onClick={handleAddBrand} variant="outlined" startIcon={<AddIcon />} className='primaryBtn-outlined'>
              Add Brand
            </Button>
          </div>
        </div>
        <div>
          {handleGetProductList?.isLoading
            ? <Spinner />
            : handleGetProductList?.status === "error"
              ? <Errors errorObject={handleGetProductList?.error} inline />
              : <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                  <thead>
                    <tr className="text-left">
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                        Sr.no
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Brand Image
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                        Brand Name
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                        Brand URL
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {handleGetProductList?.queryData?.data?.data.map((productData, idx) => {
                      const { brand_id, brand_name, brand_url, logo } = productData
                      return <tr key={idx}>
                        <td className="border-dashed border-t border-gray-200 userId">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            {idx + 1}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 firstName">
                          <div className=' w-20 h-20 mx-auto p-4'>
                            <img src={logo} alt={`${brand_name}`} className='w-full h-full object-contain' />
                          </div>
                        </td>
                        <td className="border-dashed border-t border-gray-200 firstName">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            {brand_name}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 firstName">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            {brand_url}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 phoneNumber">
                          <div className='flex items-center space-x-5 justify-center'>
                            <div>
                              <IconButton onClick={() => handleEditBrand(productData)}>
                                <EditIcon />
                              </IconButton>
                            </div>
                            <div onClick={() => handleDeleteBrand(brand_id)}>
                              <IconButton>
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </div>
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              </div>}
        </div>
      </Layout>
      <AddBrand open={isOpenAddModal} onClose={handleCloseAddModal} />
      <EditBrand open={isOpenEditModal} onClose={handleCloseEditModal} editData={editData} />
    </>
  )
}

export default Brands