import { useState } from "react"
import { useQuery } from "@tanstack/react-query";
import axios from "../../apis/axios";
import Cookies from "universal-cookie";
import { TOKEN_KEY } from "../../config";
let TOKEN = sessionStorage.getItem(TOKEN_KEY);
const cookies = new Cookies();

interface readProps {
  url: '',
  initialData?: {},
  onSuccess?: (data: any) => void,
  onError?: (data: any) => void,
  onSettled?: () => void
}
const useReadUpdated = (data: readProps) => {
  const { url, initialData, onSuccess, onError, onSettled } = data
  const [paramsObject, setGetListParams] = useState(initialData)
  const list = useQuery([url, paramsObject], () => getUsers(paramsObject), {
    
    refetchOnWindowFocus: false,
    onSuccess(data) {
      onSuccess?.(data)
    },
    onError(err) {
      onError?.(err)
    },
    onSettled() {
      onSettled?.()
    }
  })
  const getUsers = async (paramsObject: any) => {
    let token = cookies.get(TOKEN_KEY);

    let fd = new FormData()
    for (var key in paramsObject) {
      fd.append(key, paramsObject[key]);
    }
    const response = await axios({
      
      method: "POST",
      data: fd,
      url: `/${url}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    }
    })
    return response.data
  }

  return { list, paramsObject, setGetListParams }
}

export default useReadUpdated