import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config";
import Cookies from "universal-cookie";
import { generateURL } from "../helper/utiles";

// const cookies = new Cookies();
const cookies = new Cookies();

let token = sessionStorage.getItem(TOKEN_KEY);

export const getOrdersList = async (data) => {
    let token = cookies.get(TOKEN_KEY);

    console.log(data);
    // let q = key.queryKey[1];
   

    
    let fd = new FormData()
    for (var key in data) {
        fd.append(key, data[key]);
    }
    let res = await axios({ method: "GET",
     data: fd, 
      url: `${API_ENDPOINT}v1/adop/orders${generateURL(data)}`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    }
    })
    return res
};


export const getOrderPdf = async (data) => {
    let token = cookies.get(TOKEN_KEY);

    let fd = new FormData()
    for (var key in data) {
        fd.append(key, data[key]);
    }
    let res = await axios(
        {
            method: "GET",
            url: `${API_ENDPOINT}v1/ec/get-invoice?orderId=${data.id}`, 
            data: fd, 
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
    )
    return res
};