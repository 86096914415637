import axios from "axios";
import { API_ENDPOINT } from "../config";


export const handleLogin = async (data) => {
    let res = await axios({
        method: "POST",
        data: data,
        url: `https://api.ottowize.com/v1/adop/login`,
    })
    return res
};