import Layout from '../../components/layout'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Switch from '@mui/material/Switch';
import { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { getCategoryList, handleUpdateCategoryStatus } from '../../apis/category';
import Errors from '../errors';
import Spinner from '../../components/spinner';
import AddCategory from './AddCategory';
import DeleteCategory from './DeleteCategory';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import React from "react";
import EditCategory from './EditCategory';
import { useTableSearch } from '../../hooks/useTableSearch';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useNavigate, useLocation } from "react-router-dom";
import ReactPaginate from 'react-paginate';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const initialState = {
    limit: 10 ,
    pageNo: 0,
    name: '',
}
function Category() {
    const navigate = useNavigate();
    const location = useLocation();
    // const axiosPrivate = useAxiosPrivate();
    const [CategoryState, setCategoryState] = useState(initialState)
    const [dataCount, setDataCount] = useState(0)

    const categoryVisibility = useRef(null)
    const [searchVal, setSearcVal] = useState('');
    const queryClient = useQueryClient()
    const [openAddModal, setOpenAddModal] = useState(false)
    const [categoryId, setCategoryId] = useState(0)
    const handleCloseModal = () => {
        setOpenAddModal(false)
        setOpenDeleteModal(false)
        setIsOpenEditModal(false)
    }
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const handleOpenDeleteModal = (id) => {
        setOpenDeleteModal(true)
        setCategoryId(id)
    }
    const handleGetCategoryList = useQuery(['categories',CategoryState],() =>  getCategoryList(CategoryState), {
        onSuccess: (response) => {
            const { pagination } = response.data;
            
            setDataCount(Math.ceil(pagination.count / CategoryState.limit))
            
        },
        refetchOnWindowFocus: false
        
    })

    const { filteredData } = useTableSearch({
        searchVal,
        retrieve: handleGetCategoryList?.data?.data?.data
    });

    const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
    const deletedSuccess = () => {
        handleCloseModal()
        setIsDeletedSuccess(true)
    }
    const handlePageClick = (val) => {
        let data = {
            ...CategoryState,
            pageNo: val.selected
        }
        setCategoryState(data)
    }
    const [editData, setEditData] = useState({})
    const [isOpenEditModal, setIsOpenEditModal] = useState(false)
    const handleOpenEditModal = (id) => {
        setIsOpenEditModal(true)
        let data = handleGetCategoryList.data?.data?.data.find(x => x.id === id)
        setEditData(data)
    }
    const handleSearchVal = (e) => {
        let value = e.target.value
        setCategoryState(prev => {
            return {
                ...prev,
                name: value
            }
        })
    }

    return (
        <>
            <Layout>
                <div className='category'>
                    <div className='category__head headingBorder__b'>
                        <h2 className='titleHeading'>Category</h2>
                    </div>
                    <div className='category_table py-5 space-y-5'>
                        <div className='flex justify-end items-center space-x-4'>

                            <div>
                                <Button variant="outlined" startIcon={<AddIcon />} className='primaryBtn-outlined' onClick={() => setOpenAddModal(true)}>
                                    Add Category
                                </Button>
                            </div>
                        </div>
                        <div className='flex items-center space-x-4'>
                            <div className='flex-1'>
                                <input onChange={handleSearchVal} value={CategoryState.name} name='Search...' type="text" placeholder='Search by Name....' className='input' />
                            </div>
                        </div>
                        {handleGetCategoryList?.isLoading ? <Spinner />
                            : handleGetCategoryList?.status === "error" ? <Errors errorObject={handleGetCategoryList.error} inline /> :
                                <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                        <thead>
                                            <tr className="text-left">
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Sr.no
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Category Name
                                                </th>
                                                {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Image
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Status
                                                </th> */}
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData?.map((categoryData, idx) => {
                                                const { name, id } = categoryData
                                                return <tr key={idx}>
                                                    <td className="border-dashed border-t border-gray-200 userId">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                            {idx + 1}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 firstName">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-lg">
                                                            {name}
                                                        </span>
                                                    </td>
                                                    {/* <td className="border-dashed border-t border-gray-200 lastName">
                                                        <div className=' w-20 h-20 mx-auto p-4'>
                                                            <img src={image} alt={`${name}`} className='w-full h-full object-contain' />
                                                        </div>
                                                    </td> */}
                                                    {/* <td className="border-dashed border-t border-gray-200 emailAddress">
                                                        <div className='flex items-center justify-center' ref={categoryVisibility}>
                                                            {id === categoryVisibiliyId ? <Spinner className={'w-8 h-8'} /> :
                                                                <>
                                                                    <span className='text-gray-700 text-sm'>Hide</span>
                                                                    <Switch inputProps={{ 'aria-label': 'controlled' }} onChange={(e) => handleChangeStatus(e, id)} checked={home === '1' ? true : false} className='hide_show_switch' />
                                                                    <span className='text-gray-700 text-sm'>Show</span>
                                                                </>}
                                                        </div>
                                                    </td> */}
                                                    <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                        <div className='flex items-center space-x-5 justify-center'>
                                                            <div>
                                                                <IconButton onClick={() => handleOpenEditModal(id)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </div>
                                                            {/* <div onClick={() => handleOpenDeleteModal(id)}>
                                                                <IconButton>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </div> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>}
                                {filteredData?.map.length > 0 ? (
                                        <div className=" my-7">
                                             <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=">"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={dataCount}
                                                previousLabel="<"
                                                renderOnZeroPageCount={null}
                                                containerClassName='flex items-center justify-center space-x-4'
                                                pageLinkClassName='pageNumber'
                                                previousLinkClassName='pageNumber'
                                                nextLinkClassName='pageNumber'
                                                activeLinkClassName='selectedPageNumber'
                                                disabledClassName='lastPage'
                                                disabledLinkClassName='lastPage'
                                            />
                                        </div>
                                    ) : (
                                        <div className="my-7 text-center">No record found</div>
                                    )}
                    </div>
                </div>
            </Layout>
            <AddCategory open={openAddModal} onClose={handleCloseModal} />
            <EditCategory open={isOpenEditModal} onClose={handleCloseModal} editData={editData} />
            {/* <DeleteCategory open={openDeleteModal} onClose={handleCloseModal} id={categoryId} onDelete={deletedSuccess} /> */}
            {/* <Snackbar open={isDeletedSuccess} autoHideDuration={6000} onClose={() => setIsDeletedSuccess(false)}>
                <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Category Deleted Succesfully
                </Alert>
            </Snackbar> */}
        </>
    )
}

export default Category