import { Button, Dialog, IconButton } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FileUploader } from "react-drag-drop-files";
import React, { useEffect, useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { addCategory } from "../../apis/category";
import Spinner from '../../components/spinner';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { GrAdd } from "react-icons/gr";
import useCreate from "../../hooks/useCreate";

const fileTypes = ["JPG", "PNG", "JPEG"];

const tempFilterData = {
  id: 1,
  filter_attr_name: '',
  filter_attr_value: []
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function EditFrame({ open, onClose, editData }) {
  const queryClient = useQueryClient()
  const initialState = {
    frame_name: "",
    frame_id: ''
  }
  const [frameData, setFrameData] = useState(initialState)
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleInputData = (e) => {
    let value = e.target.value
    let data = {
      ...frameData,
      [e.target.name]: value,
    }
    setFrameData(data)
  }


  const handleCloseAndReset = () => {
    onClose()
    setIsSuccess(false)
    setIsError(false)
    setFrameData(initialState)
  }

  const handleBrandCreateSuccess = () => {
    handleCloseAndReset()
  }

  const handleBrandCreateError = () => {

  }


  const { setDataToServer: setFrameDataToServer } = useCreate({
    refreshUrl: 'getFrameList',
    url: 'updateFrame',
    onSuccess: handleBrandCreateSuccess,
    onError: handleBrandCreateError
  })

  const handleEditFrame = (e) => {
    e.preventDefault()
    setFrameDataToServer.mutate(frameData)
  }

  const closeErrorMessage = () => {
    setIsError(false)
  }

  useEffect(() => {
    isError && setTimeout(() => setIsError(false), [4000])
  }, [isError])

  useEffect(() => {
    setFrameData(editData)
  }, [editData])


  return (
    <>
      <Dialog fullScreen maxWidth={'md'} open={open} onClose={handleCloseAndReset} TransitionComponent={Transition}>
        <div className='container_xxl'>
          <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
            <div>
              <IconButton onClick={handleCloseAndReset}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div>
              <h1 className='heading'>Edit Frame</h1>
            </div>
          </div>
          <div className='form-body py-8'>
            <form className=' space-y-4' onSubmit={handleEditFrame}>
              <div>
                <label htmlFor="Frame Name" className='label'>Frame Name <small className="text-red-700">*</small></label>
                <input required type="text" placeholder='Enter Frame' name='frame_name' className='input' value={frameData?.frame_name} onChange={handleInputData} />
              </div>
              {isError && <Alert onClose={closeErrorMessage} className='mb-4' severity="error" sx={{ width: '100%' }}>{setFrameDataToServer?.error?.message}, Please try again later</Alert>}
              {isSuccess && <Alert onClose={closeErrorMessage} className='mb-4' severity="success" sx={{ width: '100%' }}>Category Added Succesfully</Alert>}
              <div className=' space-x-4 text-center pb-5'>
                {setFrameDataToServer.isLoading ?
                  <Spinner /> :
                  <>
                    <Button type='submit' variant="contained">Save</Button>
                    <Button variant="outlined" onClick={handleCloseAndReset}>Close</Button>
                  </>}
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default EditFrame